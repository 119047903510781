<template>
  <div class="frame-layout">
    <div class="frame-system">
        <router-view />
    </div>
    <NavBar />
  </div>
</template>
<script>
import NavBar from './components/NavBar.vue'
export default {
    components: { NavBar },
    mounted() {
    },

}
</script>

<style lang="less">
.frame-layout {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    // background: #333;
    .frame-system {
        height: calc(100% - 50px);
        // background: #fff;
    }
}
</style>